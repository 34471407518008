h2, .h2 {
    font-size: 1.8rem;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
}

.bold{
    font-weight: bold;
}

.containerTestingTop {
    background-color: red !important;
}

.navbar-nav {
    margin: 0 auto;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9);
    text-decoration: underline;
}

.card {
    margin-bottom: 15px;
    border: 0;
}

.card-header {
    padding: 0.5rem 1rem;
    background-color: inherit;
    border-bottom: 0;
}

.col-sm {
    margin-bottom: 5px;
}

.col-auto {
    margin-bottom: .2rem;
    margin-top: .2rem;
}

.card-header .header {
    font-size: 24px
}

.short-guard {
    width: 80px;    
    display: initial;
    margin: 0 8px;
}

.guardsSelector {
    width: 200px;
    float: right;
    margin-top: 1px;
    font-size: 1.2em;
}

.sortable ul[dnd-list],
.sortable .ul {
    padding: 0;
}

.sortable ul[dnd-list] .dndDraggingSource {
    display: none;
}

.sortable ul[dnd-list] .dndPlaceholder,
.sortable .ul {
    display: block;
    min-height: 100px;
    padding: 4px;
}

.sortable ul[dnd-list] li {
    display: block;
}

.sortable li {
    margin: 5px 0;
    padding: 2px 3px 0px 12px;
    font-size: 1.3em;
    cursor: move;
}

.sortable li.hide {
    background: none;
    min-height: 100px;
}

.sortable li span,
.sortable .li span {
    padding: 0 3px;
    float: right;
    cursor: pointer;
}

.panel-info.cols2 {
    width: 49%;
}

.panel-info.cols1 {
    width: 100%;
}

.panel-info {
    padding: 0 15px;
}

.center {
    text-align: center;
}

.gridjs-container {
    color: #000;
    padding: 2px;
    padding-top: 1rem;
    position: relative;
    z-index: 0;
}

.gridjs-footer, .gridjs-wrapper {
    box-shadow: 0 1px 3px 0rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .26);
    position: relative;
}

.gridjs-pagination .gridjs-summary {
    float: left;
    margin-top: 5px;
}

.gridjs-pagination {
    color: #3d4044;
    padding-top: 10px;
}

.gridjs-pagination .gridjs-pages {
    float: right;
}

.gridjs-pagination .gridjs-pages button:first-child {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}

.gridjs-pagination .gridjs-pages button:disabled, .gridjs-pagination .gridjs-pages button:hover:disabled, .gridjs-pagination .gridjs-pages button[disabled] {
    background-color: #fff;
    color: #6b7280;
    cursor: default;
}

.gridjs-pagination .gridjs-pages button {
    background-color: #fff;
    border: 1px solid #d2d6dc;
    border-right: none;
    padding: 5px 14px;
    user-select: none;
}

.gridjs-pagination .gridjs-pages button, th.gridjs-th {
    -webkit-user-select: none;
    user-select: none;
    -ms-user-select: none;
    outline: 0;
}

.gridjs-footer button, .gridjs-head button {
    background-color: transparent;
    background-image: none;
    border: none;
    cursor: pointer;
    margin: 0;
    outline: 0;
    padding: 0;
}

.gridjs-pagination .gridjs-pages button:last-child {
    border-bottom-right-radius: 6px;
    border-right: 1px solid #d2d6dc;
    border-top-right-radius: 6px;
}

button.gridjs-sort-neutral {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MDEuOTk4IiBoZWlnaHQ9IjQwMS45OTgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwMS45OTggNDAxLjk5OCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTczLjA5MiAxNjQuNDUyaDI1NS44MTNjNC45NDkgMCA5LjIzMy0xLjgwNyAxMi44NDgtNS40MjQgMy42MTMtMy42MTYgNS40MjctNy44OTggNS40MjctMTIuODQ3cy0xLjgxMy05LjIyOS01LjQyNy0xMi44NUwyMTMuODQ2IDUuNDI0QzIxMC4yMzIgMS44MTIgMjA1Ljk1MSAwIDIwMC45OTkgMHMtOS4yMzMgMS44MTItMTIuODUgNS40MjRMNjAuMjQyIDEzMy4zMzFjLTMuNjE3IDMuNjE3LTUuNDI0IDcuOTAxLTUuNDI0IDEyLjg1IDAgNC45NDggMS44MDcgOS4yMzEgNS40MjQgMTIuODQ3IDMuNjIxIDMuNjE3IDcuOTAyIDUuNDI0IDEyLjg1IDUuNDI0em0yNTUuODEzIDczLjA5N0g3My4wOTJjLTQuOTUyIDAtOS4yMzMgMS44MDgtMTIuODUgNS40MjEtMy42MTcgMy42MTctNS40MjQgNy44OTgtNS40MjQgMTIuODQ3czEuODA3IDkuMjMzIDUuNDI0IDEyLjg0OEwxODguMTQ5IDM5Ni41N2MzLjYyMSAzLjYxNyA3LjkwMiA1LjQyOCAxMi44NSA1LjQyOHM5LjIzMy0xLjgxMSAxMi44NDctNS40MjhsMTI3LjkwNy0xMjcuOTA2YzMuNjEzLTMuNjE0IDUuNDI3LTcuODk4IDUuNDI3LTEyLjg0OCAwLTQuOTQ4LTEuODEzLTkuMjI5LTUuNDI3LTEyLjg0Ny0zLjYxNC0zLjYxNi03Ljg5OS01LjQyLTEyLjg0OC01LjQyeiIvPjwvc3ZnPg==);
    background-position-y: center;
    opacity: .3;
}

button.gridjs-sort-asc {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuMzYyIiBoZWlnaHQ9IjI5Mi4zNjEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI5Mi4zNjIgMjkyLjM2MSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI4Ni45MzUgMTk3LjI4NyAxNTkuMDI4IDY5LjM4MWMtMy42MTMtMy42MTctNy44OTUtNS40MjQtMTIuODQ3LTUuNDI0cy05LjIzMyAxLjgwNy0xMi44NSA1LjQyNEw1LjQyNCAxOTcuMjg3QzEuODA3IDIwMC45MDQgMCAyMDUuMTg2IDAgMjEwLjEzNHMxLjgwNyA5LjIzMyA1LjQyNCAxMi44NDdjMy42MjEgMy42MTcgNy45MDIgNS40MjUgMTIuODUgNS40MjVoMjU1LjgxM2M0Ljk0OSAwIDkuMjMzLTEuODA4IDEyLjg0OC01LjQyNSAzLjYxMy0zLjYxMyA1LjQyNy03Ljg5OCA1LjQyNy0xMi44NDdzLTEuODE0LTkuMjMtNS40MjctMTIuODQ3eiIvPjwvc3ZnPg==);
    background-position-y: 35%;
    background-size: 10px;
}

button.gridjs-sort-desc {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuMzYyIiBoZWlnaHQ9IjI5Mi4zNjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI5Mi4zNjIgMjkyLjM2MiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI4Ni45MzUgNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiAwLTkuMjMzIDEuODA3LTEyLjg1IDUuNDI0QzEuODA3IDcyLjk5OCAwIDc3LjI3OSAwIDgyLjIyOGMwIDQuOTQ4IDEuODA3IDkuMjI5IDUuNDI0IDEyLjg0N2wxMjcuOTA3IDEyNy45MDdjMy42MjEgMy42MTcgNy45MDIgNS40MjggMTIuODUgNS40MjhzOS4yMzMtMS44MTEgMTIuODQ3LTUuNDI4TDI4Ni45MzUgOTUuMDc0YzMuNjEzLTMuNjE3IDUuNDI3LTcuODk4IDUuNDI3LTEyLjg0NyAwLTQuOTQ4LTEuODE0LTkuMjI5LTUuNDI3LTEyLjg1eiIvPjwvc3ZnPg==);
    background-position-y: 65%;
    background-size: 10px;
}

button.gridjs-sort {
    background-color: transparent;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    cursor: pointer;
    float: right;
    height: 24px;
    margin: 0;
    outline: 0;
    padding: 0;
    width: 13px;
}

th.gridjs-th-sort {
    cursor: pointer;
}

th .gridjs-th-content {
    width: calc(100% - 15px);
    float: left;
}

.gridjs-tr {
    border: none;
}

.gridjs-tr:nth-child(even) {
    background-color: #f8f9fa;
}

.gridjs-tr th:last-child {
    text-align: right;
    padding-right: 20px;
}

.gridjs th {
    background-color: #d6e0eb;
    color: #6b7280;
    padding: .5rem 0 .5rem .5rem;
}

.gridjs td {
    padding:0;
}

.gridjs .weekEven{
    background-color:#ecf0f1;
    padding-top: 3px;
    height: 45px;
}

.gridjs .weekOdd{
    background-color:#ced4da;
    padding-top: 3px;
    height: 45px;
}

.gridjs .weekEven.txt, .gridjs .weekOdd.txt{
    padding: 10px 0 0 5px;
}

.gridjs .weekEven .last .col-auto, .gridjs .weekOdd  .last .col-auto {
    margin: 0;
}

.errorMessage {
    display: none
}

.error input {
    border-color: red;
}

.error .errorMessage,
.notify {
    display: block;
    color: red;
    padding-top: 6px;
}

.planSearch {
    display: initial;
    padding-left: 20px;
}

.planSearch .react-datetime-picker {
    padding-top: 3px;
}

.react-datetime-picker__wrapper,
.react-date-picker__wrapper {
    border-radius: 0.25rem;
    line-height: 35px;
    border: 1px solid #ced4da !important;
}

.react-datetime-picker__calendar-button,
.react-date-picker__calendar-button {
    display: none
}

.form-control {
    line-height: 23px;
}

.form-label {
    margin-right: 15px;
    margin-bottom: 0;
    margin-top: 0.5rem;
}

.form-check {
    margin-top: 1rem;
}

.card {
    flex-direction: initial;
}

.col {
    float: left
}

.edit .row {
    padding-top: 1rem;
}

.narrowcol {
    width: fit-content;
}

.right {
    float: right;
}

.last {
    text-align: right;
}

.spinnerOuter {
    margin: 3px 10px;
    float: right;
}

.bgtest {
    background-color: red;
}

.container-wide{
    padding-bottom: 15px;
}

@media screen and (max-width: 896px) {
    .container-wide {
        padding-right: calc(var(--bs-gutter-x) * .1);
        padding-left: calc(var(--bs-gutter-x) * .1);
    }

    .card {
        margin: 0 0.1rem 1rem;
    }

    .planSearch {
        display: grid;
    }

    .panel-info.cols2 {
        width: 100%;
    }

    .row.last {
        width: 100%;
        display: contents;
    }

    .row.last .col {
        float: right;
        text-align: left;
    }

    .gridjs .weekEven, .gridjs .weekOdd {
        height: 95px;
    }

    .gridjs .weekEven.txt, .gridjs .weekOdd.txt{
        padding: 30px 0 0 5px;
    }
 
    .gridjs .weekEven .last .col-auto, .gridjs .weekOdd  .last .col-auto {
        padding-top: 5px;
    }
}